

class Bespoke {

    constructor() {

        this.list = document.querySelectorAll('ul.bespoke-list, ul.category-root-catlist-ul');
        this.hpList = document.querySelector('.homepage-catlist-ul');
        
        if (this.list.length) {

            this.doCheckboxTrigger();
            this.setArticlesClose();
            this.doBespokeIEGrid();

        }

        if (this.hpList && window.matchMedia('(max-width:921px)').matches) {
            this.rebuildLayoutBlocks();
        }

        this.reduceMobileMenuText();

    }

    setArticlesClose() {

    Array.from(this.list).forEach((a)=> {

        this.articleList = a.querySelectorAll('article');
        Object.keys(this.articleList).forEach((l) => {
            let _article = this.articleList[l];
            _article.addEventListener('click', (e) => {
                e.target.previousElementSibling.click();


            });

        });
});

    }

    doCheckboxTrigger() {
        Array.from(this.list).forEach((a)=> {
        
        let _checkBoxes = a.querySelectorAll('input[type=checkbox]');
        Object.keys(_checkBoxes).forEach((c) => {

            _checkBoxes[c].onclick = (e) => {

                if (e.target.checked) { 
                   this.showOverlay();

                } else {
                    this.hideOverlay();
                }
            };
        });
        });
    }

    showOverlay() {


        let ol = document.createElement('div');
        ol.classList.add('bespoke-overlay');
        document.body.insertAdjacentElement('beforeend', ol);


    }

    hideOverlay() {

        document.querySelector('.bespoke-overlay').parentNode.removeChild(document.querySelector('.bespoke-overlay'));

    }

/* set the layout for IE11 / older browser CSS grid */
doBespokeIEGrid() {
    
    let bList = document.querySelectorAll('ul.bespoke-list, ul.category-root-catlist-ul');
  
    if (!bList) {return;}
    
    Array.from(bList).forEach((b)=> {
    var s = window.getComputedStyle(b);

            if (s.display === '-ms-grid') {/* only apply to -ms-grid elements */
    
               var kids = b.children;
                var idx = 1;
                var row = 1;
                var maxColCount = (b.classList.contains('bespoke-list')) ? 7 : 5; /* this is based on a block + gap repeater of block - gap - block - gap - block - gap - block */
                Object.keys(kids).forEach(function (k) {
                    var lk = window.getComputedStyle(kids[k]);
                    /* set the row  and reset the column if necessary */
                            
                     var msStyle = `-ms-grid-column:${idx};-ms-grid-row:${row};width:260px;height:260px;margin-bottom:20px;`;
                    kids[k].setAttribute('style', 'background-image:' + lk.backgroundImage + ';' + msStyle);
                    if (idx % maxColCount  === 0) {
                        idx = 1;
                        row+= 2;
                        
                        
                    } else {
                    
                    idx += 2;
                }
                });
        
        
        }
        });
}

/* this will create a new layout for the category blocks */
rebuildLayoutBlocks() {
    
    let newNode = [];
    var liNode = [];
    let elsPerBlock = 4; /* this is the number of blocks we want to show in each LI cell */
    let kids = this.hpList.children;
    let w = this.hpList.offsetWidth;
  
    Array.from(kids).forEach(k=>{
        
        let bgImg = `url(${k.dataset.image})`;
   
        let b = k.getAttribute('style');
        let newStyle = `background-image: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%), ${bgImg};background-size:cover;`;
        let href = k.querySelector('a');
        href.classList.add('mobile-block');
        href.setAttribute('style',newStyle);
        newNode.push(href);
        k.parentNode.removeChild(k);    
   });
   /* split the array into blocks and create <li> elements containg 4 of each */
       let blockCount = Math.ceil(newNode.length / elsPerBlock);
       var blockInt = 0; /* this is the initial block index */
       var ct = 1, itemCt = 0;
       newNode.forEach(n=> {
           /* we need this because JS throws a fit if an element isn't predefined as an arrray */
            if (typeof liNode[blockInt] === 'undefined') {
                 liNode[blockInt] = [];
                
            }
       liNode[blockInt][itemCt]= n;
        
            if (ct % elsPerBlock === 0) {
                blockInt++;
                itemCt = 0;
            } 
            ct++;
            itemCt++;
        });

       liNode.forEach((i)=> {
           let li = document.createElement('li');
           li.classList.add('mobile-grid-li');
           li.style.width = w+'px';
           i.forEach((a)=> {
               li.insertAdjacentElement('beforeend',a);
               
           });
           
           this.hpList.insertAdjacentElement('beforeend',li);
             });
       
}

reduceMobileMenuText() {
    if( window.matchMedia('(max-width:991px)').matches) {
    let _items = document.querySelectorAll('.ui-menu-item.level0.static a');
    Array.from(_items).forEach((_i )=> {
        _i.textContent = _i.textContent.replace(/ Us$/,'');
    });
        }
}

}

window.addEventListener('DOMContentLoaded', () => {
    new Bespoke();
});
